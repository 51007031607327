import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody } from "app/components/Modal/components";
import Modal from "app/components/Modal";
import { H3, P2 } from "app/components/Typography";
import Thumbnail from "app/components/Thumbnail";
import Button from "app/components/Button";
import env from "helpers/env";

const AddToCurrentScheduleModal = ({
  addToCurrentSchedule,
  isOpen,
  classSlug,
  toggleModal,
}) => {
  return (
    <Modal toggleModal={toggleModal} isOpen={isOpen} maxWidth="330px">
      <ModalHeader variant="light" toggleModal={toggleModal}>
        <H3 color="blue"> Add to current day?</H3>
      </ModalHeader>
      <ModalBody mt={3}>
        <Thumbnail
          borderRadius="5px"
          src={`${env(
            "PUBLIC_ASSET_PATH"
          )}/class-thumbnails/${classSlug}-basic.jpg?w=1200`}
          placeholderSrc={`${env(
            "PUBLIC_ASSET_PATH"
          )}/class-thumbnails/${classSlug}-basic.jpg?blur=200&px=16&auto=format`}
        />
        <P2 mt={3}>
          You are about to take a class that is not in your current schedule,
          add it now to keep track of classes you take each day!
        </P2>
        <Button mt={4} onClick={addToCurrentSchedule}>
          Add To Current Schedule
        </Button>
      </ModalBody>
    </Modal>
  );
};

AddToCurrentScheduleModal.defaultProps = {
  isOpen: false,
};

AddToCurrentScheduleModal.propTypes = {
  addToCurrentSchedule: PropTypes.func.isRequired,
  classSlug: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

export default AddToCurrentScheduleModal;
